import * as dd from 'dingtalk-jsapi'
import { getToken } from '../api/user'
// import { store } from '../store'
export function getConfig(){
  console.log('runtime.permission.requestAuthCode')
  try {
      dd.runtime.permission.requestAuthCode({
        corpId: 'ding7172807d1301db49acaaa37764f94726',
        clientId: 'dingzirwbpir28w0xias',  // 清聆dd
        // corpId: 'ding2768843171f3f7e3',
        // clientId: 'dingiixbshwywoluzkws',
        
          onSuccess: function (res) {
            console.log('result', res);
            const param = {
              'app_id' : 'u8_dingtalkh5',
              'provider' : 'dingtalk_h5',
              'data':{
                  'code': res.code
              }
            };
            getToken(param)
              .then((response) => {
                if (response.success === true) {
                  console.log('acc token', response.data.token)
                  window.localStorage.setItem('AccessToken', response.data.token)
                } else {
                  console.log(response);
                  window.localStorage.setItem('AccessToken', null)
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
          onFail: function (err) {
           console.log('error', err)
          }
          
      })
  } catch (err) {
      console.log(err)
  }
}