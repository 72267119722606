import axios from 'axios';
import config from '../config'
// import { store } from '../store'
const devBaseUrl = config.apiUrl;
const proBaseUrl = config.apiUrl;

export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl;
export const TIMEOUT = 6000;

const request = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;

    if (error.response.status === 403) {
      console.log('暂无操作权限，请联系管理员开通');
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      console.log('登录状态已过期，请重新登录');
    }
  }
  return Promise.reject(error);
};

// 请求拦截器
request.interceptors.request.use((config) => {
  console.log('token',window.localStorage.getItem('AccessToken'));
  const token = window.localStorage.getItem('AccessToken')
  if (token) {
    config.headers = {
      Authorization: token,
    };
  }
  return config;
});

// 响应拦截器
request.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export default request;
