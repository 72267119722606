import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store';
import { 
  Tab, 
  Tabs,
  Grid,
  GridItem,
  Image,
  ActionSheet,
  Divider,
  Cell,
  List,
  CellGroup,
  Popup,
  Calendar,
} from 'vant';
// import * as F2 from '@antv/f2'
Vue.config.productionTip = false
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(Calendar);
// Vue.use(store)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
