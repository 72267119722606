<template>
  <div id="app">
    <van-tabbar v-if="requiresTabbar" v-model="active" route>
      <van-tabbar-item icon="records" replace to="/">数据统计</van-tabbar-item>
      <van-tabbar-item icon="user" replace to="/user">我的主页</van-tabbar-item>
    </van-tabbar>
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tabbar, TabbarItem, Dialog } from 'vant';
import { getConfig } from './utils/getConfig'
Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    requiresTabbar() {
      // 获取当前路由对象
      const route = this.$route;
      // 返回是否需要显示 Tabbar
      return route.meta.requiresTabbar !== false;
    }
  },
  methods:{
    getUser(){
      return {
        code: 0,
      }
    },
    // 向后端发送请求获取用户信息
    getUserInfo () {
      const params = {}
      this.getUser(params).then(res =>{
        if(res.code === 200){
          this.user = res.data.user
        }else{
          console.log(res.msg);
          Dialog({ message: '获取用户信息失败！' });
        }
      }).catch(err =>{
        Dialog({ message: err.message });
      })
    },
    getToken(){
      getConfig()
    }
  },
  mounted(){
    this.getToken()
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
