import request from '@/utils/request';

const api = {
  config: '/config',
  token: '/dingtalk',
};

export async function getConfig(param) {
  return request({
    url: api.config,
    method: 'get',
    params: param,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
}

export async function getToken(param) {
  return request({
    url: api.token,
    method: 'post',
    data: param,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
}
